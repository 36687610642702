import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileResponse } from '../../generated-model/model';
import { environment } from '../../environments/environment';
import { from, Observable, of } from 'rxjs';
import { Base64Response } from '../app.constants';
import { catchError, mergeMap, toArray } from 'rxjs/operators';
import Compressor from 'compressorjs';

@Injectable({ providedIn: "root" })
export class AwsRest {
  http = inject(HttpClient);

  // ฟังก์ชันบีบอัดรูปภาพโดยใช้ Compressorjs
  private compressImage(file: File): Promise<File> {
    // ถ้าไม่ใช่รูปภาพ ไม่ต้องบีบอัด
    if (!file.type.startsWith('image/')) {
      console.log(`Skipping compression for non-image: ${file.name} (${file.type})`);
      return Promise.resolve(file);
    }

    // ข้ามไฟล์ GIF เพราะการบีบอัดอาจทำให้ภาพเคลื่อนไหวเสียหาย
    if (file.type === 'image/gif') {
      console.log(`Skipping compression for GIF: ${file.name}`);
      return Promise.resolve(file);
    }

    return new Promise((resolve, reject) => {
      console.log("Compressing...");
      console.log("fileSize:",file.size);
      let quality = 0.6;
      let maxWidth = undefined;
      let maxHeight = undefined;
      if (file.size >= 5 * 1024 * 1024) {
        quality = 0.2;
      } else if (file.size >= 1 * 1024 * 1024) {
        quality = 0.3;
      }


      console.log(`Compressing ${file.name}: ${this.formatFileSize(file.size)}, quality = ${quality}`);

      new Compressor(file, {
        quality: quality,
        success: (result) => {
          const compressedFile = new File([result], file.name, {
            type: result.type,
            lastModified: Date.now()
          });

          // ตรวจสอบขนาดไฟล์หลังบีบอัด
          const originalSize = this.formatFileSize(file.size);
          const compressedSize = this.formatFileSize(compressedFile.size);
          const percentage = Math.round((1 - compressedFile.size / file.size) * 100);

          console.log(`Compressed ${file.name}: ${originalSize} -> ${compressedSize} (${percentage}% reduction)`);

          // หากการบีบอัดทำให้ไฟล์ใหญ่ขึ้นหรือเล็กลงไม่มากพอ ให้ใช้ไฟล์เดิม
          if (compressedFile.size > file.size || percentage < 5) {
            console.log(`Using original file - compression ineffective for: ${file.name}`);
            resolve(file);
          } else {
            resolve(compressedFile);
          }
        },
        error: (err) => {
          console.error(`Error compressing ${file.name}:`, err);
          resolve(file); // ถ้าเกิดข้อผิดพลาดให้ใช้ไฟล์เดิม
        }
      });
    });
  }

  // ฟังก์ชันช่วยแสดงขนาดไฟล์ในรูปแบบที่อ่านง่าย
  private formatFileSize(bytes: number): string {
    if (bytes < 1024) {
      return bytes + ' bytes';
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    }
  }

  // อัพโหลดไฟล์เดี่ยว
  upload(file: File): Observable<FileResponse> {
    // บีบอัดรูปภาพก่อนอัพโหลด
    return from(this.compressImage(file)).pipe(
      mergeMap(compressedFile => {
        const formData = new FormData();
        formData.append('file', compressedFile);
        return this.http.post<FileResponse>(`${environment.serverUrl}/api/aws/upload`, formData);
      }),
      catchError(err => {
        console.error('เกิดข้อผิดพลาดในการอัพโหลดไฟล์', err);
        throw err;
      })
    );
  }

  // อัพโหลดหลายไฟล์
  uploadMultiple(files: File[]): Observable<FileResponse[]> {
    if (!files || files.length === 0) {
      return of([]);
    }

    return from(Promise.all(files.map(file => this.compressImage(file)))).pipe(
      mergeMap(compressedFiles => {
        const formData = new FormData();
        compressedFiles.forEach(file => {
          formData.append('files', file);
        });

        return this.http.post<FileResponse[]>(`${environment.serverUrl}/api/aws/upload-multiple`, formData);
      }),
      catchError(err => {
        console.error('เกิดข้อผิดพลาดในการอัพโหลดไฟล์', err);
        throw err;
      })
    );
  }

  getImageAsBase64(fileId: number): Observable<Base64Response> {
    return this.http.get<Base64Response>(`${environment.serverUrl}/api/aws/${fileId}/base64`);
  }

  getImageUrl(fileId: number): string {
    if (!fileId) return '';
    return `${environment.serverUrl}/api/aws/${fileId}`;
  }

  delete(fileId: number): Observable<FileResponse> {
    return this.http.delete<FileResponse>(`${environment.serverUrl}/api/aws/${fileId}`);
  }
}
